/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { toast } from "react-toastify";
import React, { useEffect } from "react";
import { Button, Col, Image, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { getProfileApi } from "../../../Features/User/User";
import coin from "../../../Assets/PNG/coin.png";
import {
  getCoinsApi,
  withdrawApi,
} from "../../../Features/Wallet&Coins/WalletCoins";

import styles from "./index.module.scss";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../Routes/Routes";

function Coins() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userToken = useSelector((state) => state.user?.token);
  const coinsData = useSelector((state) => state.walletCoins?.coinsData);
  // console.log("coinsData", coinsData);
  const profileData = useSelector((state) => state.user?.profileData);
  // console.log("profileData", profileData);

  useEffect(() => {
    dispatch(getCoinsApi(userToken));
    dispatch(getProfileApi(userToken));
  }, []);

  const back = () => {
    navigate(routes.homepageUrl);
  };

  const handleWithdrawAmount = () => {
    let obj = {
      amount: profileData?.Coins,
    };
    if (profileData?.Coins >= 1000) {
      dispatch(withdrawApi(obj));
    } else {
      toast.error("Minimum Withdraw 1000");
    }
  };

  return (
    <div>
      {coinsData?.length > 0 ? (
        <>
          <h2
            style={{
              marginLeft: "45px",
            }}
          >
            My Rewards
            <Image
              src={coin}
              alt="coin"
              className={styles.setimg}
              width={30}
              height={30}
              preview={false}
              style={{
                marginLeft: "10px",
              }}
            />
          </h2>
          <Row justify="center" className={styles.welMain}>
            <Col
              xs={22}
              md={22}
              lg={22}
              xl={22}
              xxl={22}
              className={styles.setwallet}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <div className={styles.total}>₹{profileData?.Coins}</div>
                  <div className={styles.balance}>Your Rewarded Coins</div>
                </div>
                <div>
                  <Button
                    className={styles.withbtn}
                    onClick={() => handleWithdrawAmount()}
                  >
                    Withdraw
                  </Button>
                </div>
              </div>
            </Col>
            <Col
              xs={22}
              md={22}
              lg={22}
              xl={22}
              xxl={22}
              className={styles.setdata}
            >
              <div className={styles.tran}>Reward History</div>
              <div className={styles.set}>
                {coinsData?.map((item, index) => (
                  <>
                    <div className={styles.setting} key={index}>
                      <div>
                        <p>{item?.Description}</p>
                        <p>{moment(item?.createdAt).format("LL")}</p>
                      </div>
                      <p
                        className={
                          item?.Trans_Type === "Debit"
                            ? styles.money
                            : styles.credit
                        }
                      >
                        {item?.Trans_Type === "Debit" ? "-" : "+"} ₹
                        {item?.Amount}
                        <Image
                          src={coin}
                          alt="coin"
                          className={styles.setimg}
                          width={30}
                          height={30}
                          preview={false}
                          style={{
                            marginLeft: "10px",
                          }}
                        />
                      </p>
                    </div>
                    <div className={styles.blank}></div>
                  </>
                ))}
              </div>
            </Col>
          </Row>
        </>
      ) : (
        <>
          <p
            style={{
              fontSize: "20px",
            }}
          >
            No Coins History Found!
          </p>
          <Button
            style={{
              background: "black",
              color: "white",
              marginBottom: "50px",
            }}
            onClick={back}
          >
            Back to Home
          </Button>
        </>
      )}
    </div>
  );
}

export default Coins;
