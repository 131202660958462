/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getWalletApi } from "../../Features/Wallet&Coins/WalletCoins";
import { Button, Col, Row } from "antd";
import { getProfileApi } from "../../Features/User/User";
import styles from "./index.module.scss";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { routes } from "../../Routes/Routes";

function Wallet() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userToken = useSelector((state) => state.user?.token);
  const walletData = useSelector((state) => state.walletCoins?.walletData);
  // console.log("walletData", walletData);
  const profileData = useSelector((state) => state.user?.profileData);
  // console.log("profileData", profileData);

  useEffect(() => {
    dispatch(getWalletApi(userToken));
    dispatch(getProfileApi(userToken));
  }, []);

  const back = () => {
    navigate(routes.homepageUrl);
  };

  return (
    <div>
      {walletData?.length > 0 ? (
        <>
          <h2
            style={{
              marginLeft: "45px",
            }}
          >
            Wallet
          </h2>
          <Row justify="center" className={styles.welMain}>
            <Col
              xs={22}
              md={22}
              lg={22}
              xl={22}
              xxl={22}
              className={styles.setwallet}
            >
              <div className={styles.total}>₹{profileData?.Wallet}</div>
              <div className={styles.balance}>Your Wallet Balance</div>
            </Col>
            <Col
              xs={22}
              md={22}
              lg={22}
              xl={22}
              xxl={22}
              className={styles.setdata}
            >
              <div className={styles.tran}>Transaction History</div>
              <div className={styles.set}>
                {walletData?.map((item, index) => (
                  <>
                    <div className={styles.setting} key={index}>
                      <div>
                        <p>{item?.Description}</p>
                        <p>{moment(item?.createdAt).format("LL")}</p>
                      </div>
                      <p
                        className={
                          item?.Trans_Type === "Debit"
                            ? styles.money
                            : styles.credit
                        }
                      >
                        {item?.Trans_Type === "Debit" ? "-" : "+"} ₹
                        {item?.Amount}
                      </p>
                    </div>
                    <div className={styles.blank}></div>
                  </>
                ))}
              </div>
            </Col>
          </Row>
        </>
      ) : (
        <>
          <p
            style={{
              fontSize: "20px",
            }}
          >
            No Wallet History Found!
          </p>
          <Button
            style={{
              background: "black",
              color: "white",
              marginBottom: "50px",
            }}
            onClick={back}
          >
            Back to Home
          </Button>
        </>
      )}
    </div>
  );
}

export default Wallet;
