// export const BASE_URL = "http://3.109.46.220:5000";
export const BASE_URL = "https://shubhlibaas.com";
// export const BASE_URL = "192.168.29.27:5000";
// export const BASE_URL = "http://vbsofttech.com";
// export const BASE_URL = "http://192.168.29.27:5000";

export const apiUrl = {
  SEND_OTP: `${BASE_URL}/user/send-otp`,
  VERIFY_OTP: `${BASE_URL}/user/verify-otp`,
  GET_PROFILE: `${BASE_URL}/user/profile/get`,
  UPDATE_PROFILE: `${BASE_URL}/user/profile/update`,
  FIRST_UPDATE_PROFILE: `${BASE_URL}user/profile/update/generate/coupon`,

  GET_MOB_CATEGORY: `${BASE_URL}/category/mob/get`,
  GET_MOB_FEATURE_CATEGORY: `${BASE_URL}/category/mob/featurelist/get`,

  GET_BANNER: `${BASE_URL}/banner/mob/get`,
  GET_BANNER_PRODUCT: `${BASE_URL}/product/banner/mob/get`,

  GET_SEARCH_PRODUCT: `${BASE_URL}/search/get`,
  ADD_PRODUCT_NOTIFY: `${BASE_URL}/product/notify/add`,
  GET_ALL_PRODUCT: `${BASE_URL}/product/mob/get/productlist`,
  GET_SINGLE_PRODUCT_ID: `${BASE_URL}/product/mob/get/single`,
  GET_FILTER_PRODUCT: `${BASE_URL}/search/filter/get/products`,
  GET_FILTERLIST_PRODUCT: `${BASE_URL}/search/get/filterList`,
  GET_FEATURE_LIST: `${BASE_URL}/product/mob/get/features/productlist`,
  GET_PRODUCT_FEATURE_LIST: `${BASE_URL}/feature/product/mob/get/productlist`,

  ADD_TO_CART: `${BASE_URL}/cart/add`,
  GET_CART_LIST: `${BASE_URL}/cart/cartItems/get`,
  PUT_CART_LIST: `${BASE_URL}/cart/cartItems/update`,
  DELETE_CART_LIST: `${BASE_URL}/cart/cartItems/delete`,
  DELETE_ALL_CART_LIST: `${BASE_URL}/cart/all/cartItems/delete`,

  GET_WISH_LIST: `${BASE_URL}/wishlist/get`,
  ADD_WISH_LIST: `${BASE_URL}/wishlist/addremove`,

  ADD_ADDRESS: `${BASE_URL}/address/add`,
  GET_ALL_ADDRESS: `${BASE_URL}/address/get`,
  UPDATE_ADDRESS: `${BASE_URL}/address/update`,
  DELETE_ADDRESS: `${BASE_URL}/address/delete`,

  ADD_ORDER: `${BASE_URL}/order/add`,
  GET_ALL_ORDER: `${BASE_URL}/order/getAll`,
  UPDATE_ORDER: `${BASE_URL}/order/update/singleOrder/type`,
  SINGLE_ORDER_ID: `${BASE_URL}/order/get/singleOrder`,

  GET_ALL_SETTING: `${BASE_URL}/app/settings/get`,
  ADD_COUPON_CODE: `${BASE_URL}/coupon/check/couponcode/forordertime`,
  GET_COUPON_CODE: `${BASE_URL}/coupon/get/all/forordertime`,
  GET_ALL_REVIEW: `${BASE_URL}/review/mob/get/all`,
  GET_PRODUCT_REVIEW: `${BASE_URL}/review/get/product`,
  ADD_REVIEW: `${BASE_URL}/review/add`,
  GET_SINGLE_REVIEW: `${BASE_URL}/review/get/single`,

  GET_PAYMENT_GETWAY: `${BASE_URL}/processPayment`,

  GET_WALLET_HISTORY: `${BASE_URL}/wallet/history/user/get`,
  GET_COINS_HISTORY: `${BASE_URL}/coins/history/user/get`,
  GET_NOTIFICATION: `${BASE_URL}/notification/get/byuser`,
  WITHDRAW_AMOUNT: `${BASE_URL}/coins/history/withdrawal`,
};
