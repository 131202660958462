import React from "react";
import { useEffect } from "react";
import { Button, Result } from "antd";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { routes } from "../../Routes/Routes";
import { setCartCount } from "../../Features/User/User";

import styles from "./index.module.scss";

function ThankYou() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = () => {
    navigate(routes.homepageUrl);
    dispatch(setCartCount(0));
    window.location.reload();
  };
  return (
    <div className={styles.thank}>
      <Result
        status="success"
        title="Thank You For Your Order"
        subTitle="Order Successfully"
        extra={[
          <Button key="add" onClick={handleSubmit} className={styles.back}>
            Back To HomePage
          </Button>,
        ]}
      />
    </div>
  );
}

export default ThankYou;
