import "./App.css";
import UserRouting from "./Routing/UserRouting";

function App() {
  return (
    <div className="App">
      <UserRouting />
    </div>
  );
}

export default App;
