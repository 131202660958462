/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Badge,
  Button,
  Col,
  Dropdown,
  Image,
  Input,
  Menu,
  Row,
  Tooltip,
} from "antd";
import { routes } from "../../Routes/Routes";
import { useNavigate } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getProfileApi,
  logout,
  setCartCount,
  setLikeCount,
} from "../../Features/User/User";
import logo from "../../Assets/PNG/logo3.png";
import cart from "../../Assets/PNG/cart.png";
import user from "../../Assets/PNG/profile.png";
import heart from "../../Assets/PNG/like.png";
import { getCategoryApi } from "../../Features/Category/Category";
import { getSearchProductApi } from "../../Features/Product/Product";

import styles from "./index.module.scss";
import { getWishListApi } from "../../Features/WishList/WishList";
import { geCartListApi } from "../../Features/AddCart/AddCart";

function Header() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [searchType, setSearchType] = useState();
  const userId = useSelector((state) => state.user?.userId);
  const cartCounter = useSelector((state) => state.user?.cartCount);
  const likeCounter = useSelector((state) => state.user?.likeCount);
  const searchData = useSelector((state) => state.product?.searchData);
  const catagoryItem = useSelector((state) => state.category?.categoryData);
  const profileData = useSelector((state) => state.user?.profileData);
  const userToken = useSelector((state) => state.user?.token);
  const cartlist = useSelector((state) => state.addCart?.cartListData);
  const wishlist = useSelector((state) => state.wishList?.wishlist2);

  useEffect(() => {
    dispatch(getCategoryApi());
    dispatch(getProfileApi(userToken));
    dispatch(getSearchProductApi(searchType, userId ? userId : 0));
    dispatch(getWishListApi(userToken));
    dispatch(geCartListApi(userToken));
  }, []);

  const handlesubmit = () => {
    navigate(routes.homepageUrl);
    window.location.reload();
  };

  const handleCart = () => {
    // if (cartCounter > 0) {
    if (userToken) {
      navigate(routes.shoppingUrl);
    }
    // window.location.reload();
    // }
  };

  const Like = () => {
    // if (likeCounter > 0) {
    if (userToken) {
      navigate(routes.likeUrl);
    }
    // }
  };

  const Catagory = (item) => {
    setData(item);
    // window.location.reload();
    navigate(routes.catagoryUrl, {
      state: {
        item: item?.category_Name,
        id: item?.category_id,
      },
    });
  };

  const handleReset = () => {
    dispatch(logout());
    navigate(routes.userUrl);
    dispatch(setLikeCount(0));
    dispatch(setCartCount(0));
    window.location.reload();
  };

  const handleInputChange = (e) => {
    setSearchType(e);
    const onSuccessCallback = (res) => {
      if (res.products?.length > 0) {
        navigate(routes.search);
      }
    };
    dispatch(getSearchProductApi(e, userId ? userId : 0, onSuccessCallback));
  };

  const menu = (
    <Menu
      items={[
        {
          label: (
            <>
              {userId ? (
                <>
                  <p
                    style={{ fontSize: "16px", fontWeight: "700", margin: "0" }}
                  >
                    Hello {profileData?.User_Name}
                  </p>
                  <p
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      margin: "0",
                      marginBottom: "10px",
                    }}
                  >
                    {profileData?.User_Mobile_No}
                  </p>
                  <p
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      margin: "0",
                      marginBottom: "10px",
                      background: "#DAA520",
                      borderRadius: "10px",
                      textAlign: "center",
                      width: "50%",
                    }}
                  >
                    {profileData?.User_Type === "1"
                      ? "Gold"
                      : profileData?.User_Type === "2"
                      ? "Silver"
                      : profileData?.User_Type === "3"
                      ? "PPO"
                      : ""}
                  </p>
                  {/* <p
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      marginTop: "-15px",
                    }}
                  >
                    {userId}
                  </p> */}
                  <div>
                    <Button
                      style={{
                        border: "1px solid gainsboro",
                        borderRadius: "0",
                        color: "purple",
                        width: "100%",
                        height: "45px",
                        fontWeight: "600",
                        fontSize: "16px",
                      }}
                      onClick={() => {
                        navigate(routes.signupUrl, {
                          state: {
                            update: true,
                          },
                        });
                      }}
                    >
                      UPDATE PROFILE
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <p style={{ fontSize: "18px", fontWeight: "700" }}>Welcome</p>
                  <p
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      marginTop: "-20px",
                    }}
                  >
                    To access account and manage orders
                  </p>
                  <div>
                    <Button
                      style={{
                        border: "1px solid gainsboro",
                        borderRadius: "0",
                        color: "red",
                        width: "70%",
                        height: "40px",
                        fontWeight: "600",
                        fontSize: "16px",
                      }}
                      onClick={() => {
                        navigate(routes.userUrl);
                      }}
                    >
                      LOGIN / SIGNUP
                    </Button>
                  </div>
                </>
              )}
              <div
                style={{
                  border: "1px solid gainsboro",
                  marginTop: "20px",
                  padding: "0",
                }}
              ></div>
            </>
          ),
          key: "0",
        },
        {
          label: (
            <>
              {userId ? (
                <div
                  onClick={() => {
                    navigate(routes.orderUrl);
                  }}
                  style={{ fontWeight: "600", fontSize: "16px" }}
                >
                  Order
                </div>
              ) : (
                <div>Order</div>
              )}
            </>
          ),
          key: "1",
        },
        {
          label: (
            <>
              {userId ? (
                <div
                  onClick={() => {
                    navigate(routes.likeUrl);
                  }}
                  style={{ fontWeight: "600", fontSize: "16px" }}
                >
                  Wish List
                </div>
              ) : (
                <div>Wish List</div>
              )}
            </>
          ),
          key: "2",
        },
        {
          label: (
            <>
              {userId ? (
                <div
                  onClick={() => {
                    navigate(routes.addressUrl);
                  }}
                  style={{ fontWeight: "600", fontSize: "16px" }}
                >
                  My Address
                </div>
              ) : (
                <div>My Address</div>
              )}
            </>
          ),
          key: "4",
        },
        {
          label: (
            <>
              {userId ? (
                <div
                  onClick={() => {
                    navigate(routes.walletUrl);
                  }}
                  style={{ fontWeight: "600", fontSize: "16px" }}
                >
                  Wallet History
                </div>
              ) : (
                <div> Wallet History</div>
              )}
            </>
          ),
          key: "11",
        },
        {
          label: (
            <>
              {userId ? (
                <div
                  onClick={() => {
                    navigate(routes.coinsUrl);
                  }}
                  style={{ fontWeight: "600", fontSize: "16px" }}
                >
                  Rewards History
                </div>
              ) : (
                <div> Coins History</div>
              )}
            </>
          ),
          key: "12",
        },
        {
          label: (
            <>
              {userId ? (
                <div
                  onClick={() => {
                    navigate(routes.notificationUrl);
                  }}
                  style={{ fontWeight: "600", fontSize: "16px" }}
                >
                  Notifications History
                </div>
              ) : (
                <div> Notification History</div>
              )}
            </>
          ),
          key: "13",
        },
        {
          label: (
            <>
              {userId ? (
                <div
                  onClick={() => {
                    handleReset();
                  }}
                  style={{ fontWeight: "600", fontSize: "16px" }}
                >
                  Logout
                </div>
              ) : (
                ""
              )}
            </>
          ),
          key: "3",
        },
      ]}
    />
  );

  return (
    <div>
      <div className={styles.offer}>15% OFF ALL PRODUCT</div>
      <Row className={styles.headermain}>
        <Col xs={22} md={22} lg={22} xl={22} xxl={22}>
          <Row justify="space-between">
            <Col
              xs={11}
              md={10}
              lg={7}
              xl={6}
              xxl={6}
              className={styles.headerlogo}
              onClick={handlesubmit}
            >
              <Image src={logo} alt="logo" preview={false} />
            </Col>
            <Col
              md={24}
              lg={10}
              xl={11}
              xxl={12}
              className={styles.searchinput}
            >
              <Input
                size="large"
                placeholder="Search Here"
                prefix={<SearchOutlined />}
                onChange={(e) => handleInputChange(e.target.value)}
              />
            </Col>
            <Col md={3} lg={4} xl={3} xxl={3} className={styles.headericon}>
              <Dropdown overlay={menu} trigger={["click"]}>
                <div
                  className={styles.hrDropdown}
                  onClick={(e) => e.preventDefault()}
                >
                  <Image src={user} alt="profile" preview={false} />
                </div>
              </Dropdown>
              {likeCounter === 0 ? (
                <Badge
                  showZero
                  count={wishlist > 0 ? wishlist : 0}
                  className={styles.badge}
                >
                  <Image
                    src={heart}
                    alt="like"
                    preview={false}
                    onClick={Like}
                  />
                </Badge>
              ) : (
                <Tooltip>
                  <Badge
                    showZero
                    count={wishlist > 0 ? wishlist : 0}
                    className={styles.badge}
                  >
                    <Image
                      src={heart}
                      alt="like"
                      preview={false}
                      onClick={Like}
                    />
                  </Badge>
                </Tooltip>
              )}
              {cartCounter === 0 ? (
                <Badge
                  showZero
                  count={cartlist?.Count > 0 ? cartlist?.Count : 0}
                  className={styles.badge}
                >
                  <Image
                    src={cart}
                    alt="shopping"
                    preview={false}
                    onClick={handleCart}
                  />
                </Badge>
              ) : (
                <Tooltip>
                  <Badge
                    showZero
                    count={cartlist?.Count > 0 ? cartlist?.Count : 0}
                    className={styles.badge}
                  >
                    <Image
                      src={cart}
                      alt="shopping"
                      preview={false}
                      onClick={handleCart}
                    />
                  </Badge>
                </Tooltip>
              )}
            </Col>

            {/* // tablet size */}
            {/* <div> */}
            <Col
              xs={8}
              md={8}
              lg={4}
              xl={3}
              xxl={4}
              className={styles.headericon2}
            >
              <Dropdown overlay={menu} trigger={["click"]}>
                <div
                  className={styles.hrDropdown}
                  onClick={(e) => e.preventDefault()}
                >
                  <Image src={user} alt="profile" preview={false} />
                </div>
              </Dropdown>
              {likeCounter ? (
                <Badge
                  showZero
                  count={wishlist > 0 ? wishlist : 0}
                  className={styles.badge}
                >
                  <Image
                    src={heart}
                    alt="like"
                    preview={false}
                    onClick={Like}
                  />
                </Badge>
              ) : (
                <Tooltip>
                  <Badge
                    showZero
                    count={wishlist > 0 ? wishlist : 0}
                    className={styles.badge}
                  >
                    <Image
                      src={heart}
                      alt="like"
                      preview={false}
                      onClick={Like}
                    />
                  </Badge>
                </Tooltip>
              )}
              {cartCounter ? (
                <Badge
                  showZero
                  count={cartlist?.Count > 0 ? cartlist?.Count : 0}
                  className={styles.badge}
                >
                  <Image
                    src={cart}
                    alt="shopping"
                    preview={false}
                    onClick={handleCart}
                  />
                </Badge>
              ) : (
                <Tooltip>
                  <Badge
                    showZero
                    count={cartlist?.Count > 0 ? cartlist?.Count : 0}
                    className={styles.badge}
                  >
                    <Image
                      src={cart}
                      alt="shopping"
                      preview={false}
                      onClick={handleCart}
                    />
                  </Badge>
                </Tooltip>
              )}
            </Col>
            <Col
              xs={24}
              md={24}
              lg={10}
              xl={11}
              xxl={10}
              className={styles.searchinput2}
            >
              <Input
                size="large"
                placeholder="Search Here"
                prefix={<SearchOutlined />}
                onChange={(e) => handleInputChange(e.target.value)}
              />
            </Col>
            {/* </div> */}
          </Row>
        </Col>
      </Row>
      {/* <Row justify="center">
        <Col md={24} lg={24} xl={24} xxl={24} className={styles.tabs}>
          {catagoryItem?.slice(0, 6)?.map((item, index) => (
            <div key={index}>
              <p onClick={() => Catagory(item)}>{item?.category_Name}</p>
            </div>
          ))}
        </Col>
      </Row> */}
    </div>
  );
}

export default Header;
