/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { EditFilled } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Col, Form, Image, Input, Row } from "antd";

import {
  getProfileApi,
  updateFirstTimeApi,
  updateProfileApi,
} from "../../../Features/User/User";
import logo from "../../../Assets/PNG/logo.png";
import { routes } from "../../../Routes/Routes";

import styles from "./index.module.scss";
import axios from "axios";
import { imageUploadApiCall } from "../../../Services/CommonService";

function Signup() {
  const [form] = Form.useForm();
  const { state } = useLocation();
  const [img, setImg] = useState("");
  const [img2, setImg2] = useState("");
  // console.log("img2", img2);
  const [img3, setImg3] = useState("");
  // const [images, setImages] = useState();
  // // console.log("images", images);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((state) => state.user?.token);
  const profileData = useSelector((state) => state.user?.profileData);
  const loader = useSelector((state) => state.user?.isLoginLoading);

  useEffect(() => {
    dispatch(getProfileApi(token));
    window.scrollTo(0, 0);
  }, []);

  // useEffect(() => {
  //   setImages(img2);
  // }, [img2]);

  const handleChange = (value) => {
    // console.log(`selected ${value}`);
  };

  const login = (value) => {
    // console.log("value", value);
    // const img2 = img?.slice(5);
    // const img4 = img3?.slice(5);
    const formData = new FormData();
    // formData.append("email", value?.User_Email);
    formData.append("image", img2);
    // formData.append("mobileNumber", value?.User_Mobile_No);
    // formData.append("name", value?.User_Name);

    let obj = {
      email: value?.User_Email,
      image: img2,
      mobileNumber: value?.User_Mobile_No,
      name: value?.User_Name,
    };
    console.log("obj", obj);
    const onSuccessCallback = () => {
      navigate(routes.homepageUrl);
      form.resetFields();
    };
    if (state?.update === true) {
      dispatch(updateProfileApi(obj, token, onSuccessCallback));
    } else {
      dispatch(updateFirstTimeApi(obj, token, onSuccessCallback));
    }
  };

  const onImageChange = (e) => {
    // console.log("essss", e);
    setImg(URL.createObjectURL(e.target?.files?.[0]));
    setImg2(e.target?.files?.[0]);
  };

  const handleUpdateImage = (e) => {
    // console.log("essss", e.target?.files);
    setImg3(URL.createObjectURL(e.target?.files?.[0]));
    setImg2(e.target?.files?.[0]);
  };

  return (
    <div>
      <Row justify="center" className={styles.main}>
        <Col xl={7} xxl={7} className={styles.user}>
          <div className={styles.setuser}>
            <Image
              src={logo}
              alt="logo"
              preview={false}
              className={styles.logo}
            />
            {state?.update === true ? (
              <p className={styles.profile}>
                Enter details to complete your <br /> <b>Update profile</b>
              </p>
            ) : (
              <p className={styles.profile}>
                Enter details to complete your profile
              </p>
            )}
            <Form
              onFinish={login}
              form={form}
              name="sign-form"
              autoComplete="false"
              initialValues={profileData}
            >
              <Form.Item name="User_Image">
                <div
                  className={
                    img || state?.update === true
                      ? styles.upload
                      : styles.upload2
                  }
                >
                  <input
                    type="file"
                    name="file"
                    className={styles.uploadImg}
                    onChange={(e) => onImageChange(e)}
                  />
                  {img || img3 || state?.update === true ? (
                    <>
                      {img3 ? (
                        ""
                      ) : (
                        <Image
                          src={
                            state?.update === true
                              ? profileData?.User_Image
                              : img
                          }
                          alt="setImg"
                          preview={false}
                          name="User_Image"
                          className={styles.showImg}
                        />
                      )}
                      {img3 ? (
                        <>
                          <Image
                            src={img3}
                            alt="setImg"
                            preview={false}
                            name="User_Image"
                            className={styles.showImg}
                          />
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    <>
                      <div className={styles.selectImg}>Click to upload</div>
                    </>
                  )}
                  {state?.update === true ? (
                    <>
                      <EditFilled
                        style={{
                          marginTop: "113px",
                          cursor: "pointer",
                          fontSize: "24px",
                        }}
                      />
                      <input
                        type="file"
                        name="file"
                        onChange={(e) => handleUpdateImage(e)}
                        style={{
                          width: "20px",
                          height: "20px",
                          position: "relative",
                          marginTop: "113px",
                          marginLeft: "-22px",
                          opacity: "0",
                          cursor: "pointer",
                        }}
                      />
                    </>
                  ) : (
                    <>
                      {img ? (
                        <div>
                          <EditFilled
                            style={{
                              fontSize: "24px",
                              marginTop: "118px",
                              marginLeft: "-12px",
                              cursor: "pointer",
                            }}
                          />
                          <input
                            type="file"
                            name="file"
                            onChange={(e) => onImageChange(e)}
                            style={{
                              width: "20px",
                              height: "20px",
                              position: "relative",
                              marginTop: "-23px",
                              marginLeft: "-10px",
                              opacity: "0",
                              cursor: "pointer",
                            }}
                          />
                        </div>
                      ) : (
                        <EditFilled
                          style={{
                            marginTop: "113px",
                            marginLeft: "-19px",
                            cursor: "pointer",
                            fontSize: "24px",
                          }}
                        />
                      )}
                    </>
                  )}
                </div>
              </Form.Item>
              <Form.Item
                name="User_Name"
                className={styles.name}
                rules={[{ required: true }]}
              >
                <Input placeholder="Fullname" />
              </Form.Item>
              <Form.Item
                name="User_Mobile_No"
                className={styles.name}
                // rules={[
                //   {
                //     required: true,
                //   },
                //   {
                //     pattern: new RegExp(
                //       /^[\\+]?[(]?[0-9]{2}[)]?[-\s\\.]?[0-9]{2}[-\s\\.]?[0-9]{4,6}$/im
                //     ),
                //     message: "number not valid!",
                //   },
                // ]}
              >
                <Input
                  placeholder="+91 | Mobile Number"
                  defaultValue={state?.User_Mobile_No}
                  disabled
                />
              </Form.Item>
              <Form.Item
                name="User_Email"
                className={styles.name}
                rules={[
                  { required: true },
                  {
                    pattern: new RegExp(
                      /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,}$/
                    ),
                    message: "email not valid!",
                  },
                ]}
              >
                <Input placeholder="Email Address" />
              </Form.Item>
              {/* {state?.update === true ? (
                ""
              ) : (
                <Form.Item className={styles.select}>
                  <Select
                    className={styles.selectinput}
                    placeholder="City"
                    style={{ width: "100%" }}
                    onChange={handleChange}
                    tokenSeparators={[","]}
                    options={[
                      {
                        value: "Surat",
                        label: "Surat",
                      },
                    ]}
                  />
                </Form.Item>
              )} */}
              <Form.Item className={styles.mainbtn}>
                <Button
                  htmlType="submit"
                  className={styles.save}
                  loading={loader}
                >
                  Continue
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Signup;
